import firebase from "firebase";
import { useEffect, useRef } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { NewsEntry } from "../../types/news";
import HeadlineWithDate from "../components/headlines/HeadlineWithDate";
import NewsItem from "./layout/NewsItem";
import Loading from "../components/Loading";
import { auth } from "../firebase/firebase";
import SecondaryAnchor from "../components/anchors/SecondaryAnchor";
import ArticleContainer from "../components/layout/ArticleContainer";
import { USER_COLLECTION, NEWS_COLLECTION } from "../firebase/firestoreConstants";
import useCurrentCompanyDoc from "../firebase/useCurrentCompanyDoc";

type Params = {
  newsId: string;
};

type LikeButtonProps = {
  newsId: string;
  isLiked: boolean;
  likes: number;
  companyDoc: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
};

const markNewsAsRead = async (
  newsId: string,
  companyDoc: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
) => {
  const { currentUser } = auth;
  if (currentUser) {
    const currentUserRef = await companyDoc.collection(USER_COLLECTION).doc(currentUser.uid).get();
    if (currentUserRef.exists) {
      currentUserRef.ref
        .update({
          readNewsArray: firebase.firestore.FieldValue.arrayUnion(newsId),
        })
        .catch((writeError) => {
          console.error("Error adding News read News of user: ", writeError);
        });
    } else {
      await currentUserRef.ref.set(
        {
          readNewsArray: [newsId],
        },
        { merge: true },
      );
    }
  } else {
    throw new Error("User was not logged in. Aborting marking news as read.");
  }
};

const likeNewsEntry = async (
  newsId: string,
  companyDoc: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
) => {
  const { currentUser } = auth;
  if (currentUser) {
    const newsEntryRef = await companyDoc.collection(NEWS_COLLECTION).doc(newsId).get();
    if (newsEntryRef.exists) {
      newsEntryRef.ref
        .update({
          likedByUsers: firebase.firestore.FieldValue.arrayUnion(currentUser.uid),
        })
        .catch((writeError) => {
          console.error(
            "Error occurred while adding user to likedByUsers array in news entry: ",
            writeError,
          );
        });
    } else {
      throw new Error("User was trying to like a news entry that does not exist.");
    }
  } else {
    throw new Error(
      "User was not logged in. Aborting adding user to likedByUsers array in news entry.",
    );
  }
};

const unlikeNewsEntry = async (
  newsId: string,
  companyDoc: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
) => {
  const { currentUser } = auth;
  if (currentUser) {
    const newsEntryRef = await companyDoc.collection(NEWS_COLLECTION).doc(newsId).get();
    if (newsEntryRef.exists) {
      newsEntryRef.ref
        .update({
          likedByUsers: firebase.firestore.FieldValue.arrayRemove(currentUser.uid),
        })
        .catch((writeError) => {
          console.error(
            "Error occurred while deleting user from likedByUsers array in news entry: ",
            writeError,
          );
        });
    } else {
      throw new Error("User was trying to unlike a news entry that does not exist.");
    }
  } else {
    throw new Error(
      "User was not logged in. Aborting deleting user from likedByUsers array in news entry.",
    );
  }
};

const LikeButton = ({ isLiked, newsId, likes, companyDoc }: LikeButtonProps) => {
  const toggleLike = () => {
    if (!isLiked) {
      likeNewsEntry(newsId, companyDoc).catch((error) => {
        console.error(
          "Error occurred while adding user to likedByUsers array in news entry: ",
          error,
        );
      });
    } else {
      unlikeNewsEntry(newsId, companyDoc).catch((error) => {
        console.error(
          "Error occurred while deleting user from likedByUsers array in news entry: ",
          error,
        );
      });
    }
  };

  return (
    <div className="flex flex-row items-center">
      <button
        className={`m-2 h-6 w-6 animate-[wave_5s_ease-in-out_2]
        transition transform hover:scale-150  fill-current 
       ${isLiked ? "text-red-500" : "text-info-color"}`}
        onClick={toggleLike}
      >
        <svg viewBox="0 0 14.15 12.59">
          <path
            d="M578.65,660.68c-4-.07-7.65,6.15,3.06,12.59a0,0,0,0,1,.07,0c11.06-6.65,6.79-13.06,2.68-12.56a3.19,3.19,0,0,0-2.71,1.85,3.22,3.22,0,0,0-2.72-1.85,2.43,2.43,0,0,0-.38,0Z"
            transform="translate(-574.67 -660.68)"
          />
        </svg>
      </button>
      <span className="font-bold">{likes}</span>
    </div>
  );
};

const FullVersionNews = ({ match }: RouteComponentProps<Params>): JSX.Element => {
  const { currentUser } = auth;
  const currentUserId = currentUser ? currentUser.uid : "";
  const history = useHistory();
  const newsIdFromRouter: string = match.params.newsId;
  const companyDoc = useCurrentCompanyDoc();
  const companyDocRef = useRef(companyDoc);
  companyDocRef.current = companyDoc;

  const [value, loading, error] = useDocument(
    companyDoc.collection(NEWS_COLLECTION).doc(newsIdFromRouter),
  );

  const news = value?.data() as unknown as NewsEntry;

  useEffect(() => {
    markNewsAsRead(newsIdFromRouter, companyDocRef.current).catch((e) => {
      console.error("Error occurred while marking news as read: ", e);
    });
  }, [newsIdFromRouter]);

  if (loading) {
    return <Loading />;
  }

  if (error || !news) {
    console.error("There was an error while fetching the news article from the database: ", error);
    history.push("/news");
  }

  return (
    <ArticleContainer>
      <NewsItem>
        <div className="flex flex-col flex-grow gap-4 sm:p-7 sm:pb-4">
          <HeadlineWithDate newsEntry={news} />
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: news.content }} />
          <div className={`border-t border-info-color`} />
          <div
            className={"flex flex-col gap-4 items-center sm:flex-row sm:justify-between sm:pt-2"}
          >
            <LikeButton
              newsId={newsIdFromRouter}
              isLiked={news.likedByUsers?.includes(currentUserId) ?? false}
              likes={news.likedByUsers ? news.likedByUsers.length : 0}
              companyDoc={companyDoc}
            />
            <div className="sm:w-44 w-full">
              <SecondaryAnchor label="Zurück" to="/" />
            </div>
          </div>
        </div>
      </NewsItem>
    </ArticleContainer>
  );
};

export default FullVersionNews;
