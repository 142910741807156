import { useContext, createContext } from "react";
import firebase from "firebase";

export type UserContextValue = {
  user: firebase.User;
  company: string;
};

export const UserContext = createContext<UserContextValue | null>(null);

const errorMessage = "Using user outside of context.";

export function useCurrentUser(): firebase.User {
  const userContextValue = useContext(UserContext);
  if (userContextValue == null) {
    throw new Error(errorMessage);
  }
  return userContextValue.user;
}

export function useCurrentCompany(): string {
  const userContextValue = useContext(UserContext);
  if (userContextValue == null) {
    throw new Error(errorMessage);
  }
  return userContextValue.company;
}
