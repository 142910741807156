import { Link } from "react-router-dom";

type PrimaryAnchorProps = {
  label: string;
  to: string;
};

const PrimaryAnchor = ({ label, to }: PrimaryAnchorProps): JSX.Element => (
  <Link
    className="rounded-lg bg-primary-main-color hover:bg-primary-main-color text-white text-center font-bold w-full outline-none py-3"
    to={to}
  >
    {label}
  </Link>
);

export default PrimaryAnchor;
