type SurveyItemContainerProps = {
  children: React.ReactNode;
};

const SurveyItemContainer = ({ children }: SurveyItemContainerProps): JSX.Element => (
  <div className="flex flex-col items-center justify-center bg-white px-5 pb-14 pt-12 md:pt-16">
    {children}
  </div>
);

export default SurveyItemContainer;
