type FeedbackProps = {
  display: boolean;
  isError: boolean;
  text: string;
};

const Feedback = ({ display, isError, text }: FeedbackProps): JSX.Element => (
  <>
    {display && (
      <p
        className={`bg-white bg-opacity-50 rounded-lg text-center p-5 my-2 ${
          isError ? "text-error-color" : "text-primary-dark-color"
        }`}
      >
        {text}
      </p>
    )}
  </>
);

export default Feedback;
