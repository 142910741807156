import React from "react";
import SurveyItemContainer from "./layout/SurveyItemContainer";

const NoSurvey = (): JSX.Element => (
  <SurveyItemContainer>
    <h2 className="text-2xl font-bold pb-4">Keine Umfragen vorhanden</h2>
    <img className="w-20 pb-12" src="/no-survey.svg" alt="An empty box" />
    <p>Momentan gibt es keine Umfragen. Komm später nochmal.</p>
  </SurveyItemContainer>
);

export default NoSurvey;
