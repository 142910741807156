import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import Survey from "./survey/Survey";
import News from "./news/News";
import { createComponentWithAuth } from "./firebase/firebase";
import PasswordReset from "./password_reset/PasswordReset";
import FullVersionNews from "./news/FullVersionNews";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./login/Login";
import StaticContent from "./static_content/StaticContent";
import NoMatch from "./components/NoMatch";
import AppErrorBoundary from "./components/AppErrorBoundary";

const queryClient = new QueryClient();

function App() {
  return (
    <AppErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Switch>
            <ProtectedRoute path="/survey" component={Survey} />
            <ProtectedRoute path="/news/:newsId" component={FullVersionNews} />
            <ProtectedRoute path="/news" component={News} />
            <ProtectedRoute path="/:contentName(buddy|story|score)" component={StaticContent} />
            <Route path="/passwordreset">
              <PasswordReset />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <ProtectedRoute exact path="/" component={News} />
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </Router>
      </QueryClientProvider>
    </AppErrorBoundary>
  );
}

export default createComponentWithAuth(App);
