const getCustomDateFormat = (date: Date | undefined): string | undefined => {
  if (!date) {
    return undefined;
  }

  const options = { day: "numeric", month: "long", year: "numeric" } as const;
  return new Intl.DateTimeFormat("de-DE", options).format(date);
};

export default getCustomDateFormat;
