import { useLocation } from "react-router-dom";
import UnauthenticatedView from "./layout/UnauthenticatedView";
import PrimaryAnchor from "./anchors/PrimaryAnchor";

const NoMatch = (): JSX.Element => {
  const location = useLocation();

  return (
    <UnauthenticatedView>
      <h1 className="text-2xl mb-2">
        <b>Whoops. 404 - Page Not Found</b>
      </h1>
      <div className="mb-8">
        Die angefragte URL <code>{location.pathname}</code> konnte nicht gefunden werden.
      </div>
      <PrimaryAnchor to="/" label="Zurück zur Startseite" />
    </UnauthenticatedView>
  );
};

export default NoMatch;
