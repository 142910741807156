import React from "react";

type ArticleContainerProps = {
  children: React.ReactNode;
};

const ArticleContainer = ({ children }: ArticleContainerProps): JSX.Element => (
  <div className="w-11/12 lg:w-9/12 xl:w-1/2 py-3 space-y-2" data-cy="ArticleContainer">
    {children}
  </div>
);

export default ArticleContainer;
