import { Route, RouteProps } from "react-router-dom";
import { WrappedComponentProps as AuthComponentProps } from "react-with-firebase-auth";
import { useQuery } from "react-query";
import { auth, createComponentWithAuth } from "./firebase/firebase";
import Login from "./login/Login";
import AuthenticatedView from "./components/layout/AuthenticatedView/AuthenticatedView";
import Loading from "./components/Loading";
import { UserContextValue, UserContext } from "./firebase/getUserContext";

type ProtectedRouteProps = {
  path: string;
};

const ProtectedRoute = ({
  path,
  component: Component,
  user,
}: { component: React.FunctionComponent<any> } & ProtectedRouteProps &
  RouteProps &
  AuthComponentProps): JSX.Element => {
  const companyData = useQuery(
    ["companyClaim", user?.uid],
    async () => {
      const result = await user?.getIdTokenResult();
      if (result?.claims.company) {
        return result.claims.company as string;
      }
      await auth.signOut();
      throw new Error("No company provided. Signing user out.");
    },
    { enabled: !!user },
  );

  if (!user || companyData.isError) {
    return <Login />;
  }

  if (companyData.isLoading) {
    return <Loading />;
  }

  if (!companyData.isSuccess) {
    return <Login />;
  }

  const userContextValue: UserContextValue = {
    user,
    company: companyData.data,
  };

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Route
      path={path}
      render={(props) => (
        <UserContext.Provider value={userContextValue}>
          <AuthenticatedView>
            <Component {...props} user={user} />
          </AuthenticatedView>
        </UserContext.Provider>
      )}
    />
  );
  /* eslint-disable react/jsx-props-no-spreading */
};

export default createComponentWithAuth(ProtectedRoute);
