import React from "react";

type SubHeadlineProps = {
  children: React.ReactNode;
};

const SubHeadline = ({ children }: SubHeadlineProps): JSX.Element => (
  <h2 className="font-bold block text-3xl text-center pb-8">{children}</h2>
);

export default SubHeadline;
