import firebase from "firebase";

type WelcomeMessageProps = {
  user: firebase.User;
};

const WelcomeMessage = ({ user }: WelcomeMessageProps): JSX.Element => (
  <div className="text-center text-white text-xl font-medium bg-primary-dark-color p-5">
    Willkommen zurück {user.displayName ? ` ${user.displayName}` : ""}!
  </div>
);

export default WelcomeMessage;
