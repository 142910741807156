import React from "react";
import SecondaryAnchor from "../components/anchors/SecondaryAnchor";
import SurveyItemContainer from "./layout/SurveyItemContainer";

const AllQuestionsAnswered = (): JSX.Element => (
  <>
    <SurveyItemContainer>
      <h2 className="text-2xl font-bold pb-4">Alles erledigt 🎉</h2>
      <p className="text-center p-5">
        Du hast die aktuellste Umfrage bereits beantwortet. Komm später wieder um die nächste
        Umfrage zu bearbeiten und deinen Score aufrecht zu erhalten.
      </p>
    </SurveyItemContainer>
    <div className="flex items-end justify-end ">
      <div className="pt-5">
        <div className="w-44">
          <SecondaryAnchor label="Zurück" to="/" />
        </div>
      </div>
    </div>
  </>
);

export default AllQuestionsAnswered;
