import UnauthenticatedView from "./layout/UnauthenticatedView";

const ErrorFallback = (): JSX.Element => (
  <UnauthenticatedView>
    <h1 className="text-2xl mb-2">
      <b>Whoops. Something went wrong.</b>
    </h1>
    <div className="mb-8">
      Es ist leider ein Fehler aufgetreten. Bitte versuche es später erneut.
    </div>
    <a
      href="/"
      className="rounded-lg bg-primary-main-color hover:bg-primary-main-color text-white text-center font-bold w-full outline-none py-3"
    >
      Zurück zur Startseite
    </a>
  </UnauthenticatedView>
);

export default ErrorFallback;
