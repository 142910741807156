import { FormEvent, useState } from "react";
import QuestionType from "./types/QuestionType";
import { Question as QuestionValues } from "./types/Question";
import SurveyItemContainer from "./layout/SurveyItemContainer";
import PrimaryButton from "../components/buttons/PrimaryButton";
import { USER_COLLECTION, ANSWER_COLLECTION } from "../firebase/firestoreConstants";
import useCurrentCompanyDoc from "../firebase/useCurrentCompanyDoc";
import { useCurrentUser } from "../firebase/getUserContext";
import { toAnswerId } from "./answerUtils";

type MoodItemProps = {
  imgSrc: string;
  imgAlt: string;
  name: string;
  label: string;
  answer: string;
  answerQuestion: (mood: string) => void;
};

type MoodQuestionProps = {
  answerQuestion: (mood: string) => void;
};

type BooleanItemProps = {
  name: string;
  label: string;
  answer: boolean;
  answerQuestion: (mood: boolean) => void;
};

type BooleanQuestionProps = {
  answerQuestion: (answer: boolean) => void;
};

type QuestionProps = { surveyId: string; question: QuestionValues; currentQuestion: number };

const MoodItem = ({
  imgSrc,
  imgAlt,
  name,
  label,
  answer,
  answerQuestion,
}: MoodItemProps): JSX.Element => (
  <li>
    <button
      className="flex flex-col items-center justify-center text-center space-y-2 hover:bg-purple-100 rounded-xl"
      name={name}
      type="button"
      onClick={() => answerQuestion(answer)}
    >
      <img className="w-24" src={imgSrc} alt={imgAlt} />
      <span className="text-primary-main-color font-bold">{label}</span>
    </button>
  </li>
);

const MoodQuestion = ({ answerQuestion }: MoodQuestionProps) => (
  <ol className="flex md:flex-row flex-col md:space-x-16">
    <MoodItem
      imgSrc="/sad.svg"
      imgAlt="A sad face"
      name="unhappyAnswer"
      label="Schlecht"
      answer="Schlecht"
      answerQuestion={answerQuestion}
    />
    <MoodItem
      imgSrc="/neutral.svg"
      imgAlt="A neutral face"
      name="neutralAnswer"
      label="Neutral"
      answer="Neutral"
      answerQuestion={answerQuestion}
    />
    <MoodItem
      imgSrc="/happy.svg"
      imgAlt="A happy face"
      name="happyAnswer"
      label="Gut"
      answer="Gut"
      answerQuestion={answerQuestion}
    />
  </ol>
);

const BooleanItem = ({ name, label, answer, answerQuestion }: BooleanItemProps) => (
  <li className="list-none text-primary-main-color">
    <button
      className="bg-primary-main-color hover:bg-primary-dark-color rounded-lg shadow-lg w-44 sm:w-60 md:w-36 p-5"
      name={name}
      type="button"
      onClick={() => answerQuestion(answer)}
    >
      <span className="font-bold text-white">{label}</span>
    </button>
  </li>
);

const BooleanQuestion = ({ answerQuestion }: BooleanQuestionProps) => (
  <ol className="flex flex-col md:flex-row items-center md:justify-between w-full space-y-5 md:space-y-0 px-5 sm:px-10">
    <BooleanItem name="trueAnswer" answer label="Ja" answerQuestion={answerQuestion} />
    <BooleanItem name="falseAnswer" answer={false} label="Nein" answerQuestion={answerQuestion} />
  </ol>
);

const TextQuestion = ({ answerQuestion }: { answerQuestion: (answer: string) => void }) => {
  const maxLength = 500;
  const [answer, setAnswer] = useState("");
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const onSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    answerQuestion(answer);
  };

  if (submitButtonDisabled && answer.length > 0) {
    setSubmitButtonDisabled(false);
  } else if (!submitButtonDisabled && answer.length === 0) {
    setSubmitButtonDisabled(true);
  }
  return (
    <form className="w-full" onSubmit={onSubmitHandler}>
      <textarea
        maxLength={maxLength}
        className="w-full bg-gray-200 border-2 border-primary-dark-color rounded-lg h-56"
        onChange={(e) => setAnswer(e.target.value)}
      />
      <p className="text-right text-xs">
        {maxLength - answer.length}/{maxLength}
      </p>
      <PrimaryButton
        name="submit"
        label="Absenden"
        isSubmitButton
        inverseColor
        disabled={submitButtonDisabled}
      />
    </form>
  );
};

const Question = ({ surveyId, question, currentQuestion }: QuestionProps): JSX.Element => {
  const companyDoc = useCurrentCompanyDoc();
  const currentUser = useCurrentUser();

  const answerQuestion = (answer: string | boolean) => {
    const answerId = toAnswerId(surveyId, question.id);
    companyDoc
      .collection(USER_COLLECTION)
      .doc(currentUser.uid)
      .collection(ANSWER_COLLECTION)
      .doc(answerId)
      .set({ answer })
      .finally(() => {});
  };

  return (
    <SurveyItemContainer>
      <span className="text-center text-2xl text-primary-main-color font-bold pt-5 pb-10">
        Frage {currentQuestion}
      </span>
      <p className="text-centers text-xl pb-12 md:px-12 ">{question.title}</p>
      {question.type === QuestionType.mood && <MoodQuestion answerQuestion={answerQuestion} />}
      {question.type === QuestionType.boolean && (
        <BooleanQuestion answerQuestion={answerQuestion} />
      )}
      {question.type === QuestionType.text && <TextQuestion answerQuestion={answerQuestion} />}
    </SurveyItemContainer>
  );
};

export default Question;
