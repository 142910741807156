import React from "react";

type UnauthenticatedViewProps = {
  children: React.ReactNode;
};

const UnauthenticatedView = ({ children }: UnauthenticatedViewProps): JSX.Element => (
  <div className="flex flex-col items-center justify-center bg-gradient-to-r from-primary-main-color to-primary-dark-color text-white min-h-screen">
    <div className="flex flex-col items-center justify-center bg-white bg-opacity-25 md:rounded-md h-screen lg:h-auto w-full lg:w-5/12 xl:max-w-xl p-10 md:p-16">
      <img className="w-80 pb-24" src="/temp-logo.svg" alt="Lines with change mate between them" />
      <div className="flex flex-col w-full max-w-md ">{children}</div>
    </div>
    <div className="text-center text-sm">
      <a
        href="https://accilium.com/en/legal-notice/"
        rel="noreferrer"
        target="_blank"
        className="flex w-full underline px-2 py-4"
      >
        Impressum
      </a>
    </div>
  </div>
);

export default UnauthenticatedView;
