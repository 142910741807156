import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import withFirebaseAuth from "react-with-firebase-auth";

const {
  REACT_APP_FIREBASE_API_KEY: apiKey,
  REACT_APP_FIREBASE_AUTH_DOMAIN: authDomain,
  REACT_APP_FIREBASE_PROJECT_ID: projectId,
  REACT_APP_FIREBASE_STORAGE_BUCKET: storageBucket,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: messagingSenderId,
  REACT_APP_FIREBASE_APP_ID: appId,
  REACT_APP_FIREBASE_MEASUREMENT_ID: measurementId,
} = process.env;

function getCreateComponentWithAuthHOC() {
  const firebaseApp = getFirebaseApp();
  const firebaseAppAuth = firebaseApp.auth();

  const createComponentWithAuth = withFirebaseAuth({
    providers: {
      googleProvider: new firebase.auth.GoogleAuthProvider(),
    },
    firebaseAppAuth,
  });

  firebaseAppAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).finally(() => {});

  return createComponentWithAuth;
}

function getFirebaseApp() {
  let firebaseApp: firebase.app.App;
  if (!firebase.apps.length) {
    firebaseApp = firebase.initializeApp({
      apiKey,
      authDomain,
      projectId,
      storageBucket,
      messagingSenderId,
      appId,
      measurementId,
    });
  } else {
    [firebaseApp] = firebase.apps;
  }
  return firebaseApp;
}

function getFirestore() {
  const firestore = firebase.firestore();

  if (process.env.NODE_ENV === "development") {
    firebase.firestore().settings({ experimentalForceLongPolling: true });
    firestore.useEmulator("localhost", 8080);
  } else {
    firebase.firestore().settings({ experimentalAutoDetectLongPolling: true });
  }

  return firestore;
}

function getAuth() {
  const firebaseApp = getFirebaseApp();
  const auth = firebaseApp.auth();

  if (process.env.NODE_ENV === "development") {
    auth.useEmulator("http://localhost:9099");
  }

  return auth;
}

const createComponentWithAuth = getCreateComponentWithAuthHOC();
const firestore = getFirestore();
const auth = getAuth();

export { createComponentWithAuth, firestore, auth };
export default firebase;
