import { COMPANY_COLLECTION } from "./firestoreConstants";
import { useCurrentCompany } from "./getUserContext";
import { firestore } from "./firebase";
import firebase from "firebase";

function useCurrentCompanyDoc(): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> {
  const company = useCurrentCompany();
  return firestore.collection(COMPANY_COLLECTION).doc(company);
}

export default useCurrentCompanyDoc;
