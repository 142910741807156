type PrimaryButtonProps = {
  name: string;
  label: string;
  disabled?: boolean;
  isSubmitButton?: boolean;
  inverseColor?: boolean;
  onClick?: () => void;
};

const PrimaryButton = ({
  name,
  label,
  disabled = false,
  isSubmitButton = false,
  inverseColor = false,
  onClick,
}: PrimaryButtonProps): JSX.Element => (
  <button
    name={name}
    className={`rounded-lg text-center font-bold w-full outline-none py-3 disabled:opacity-50 ${
      inverseColor
        ? "bg-primary-main-color hover:bg-primary-dark-color text-white"
        : "text-primary-main-color hover:text-white bg-white hover:bg-primary-dark-color"
    }`}
    type={isSubmitButton ? "submit" : "button"}
    onClick={onClick}
    disabled={disabled}
  >
    {label}
  </button>
);

export default PrimaryButton;
