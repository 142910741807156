import firebase from "firebase/app";
import Questions from "./Questions";
import useSurvey from "./useSurvey";
import SurveyContainer from "./layout/SurveyContainer";

type SurveyProps = {
  user: firebase.User;
};

const Survey = ({ user }: SurveyProps): JSX.Element => {
  const [survey, loading, error] = useSurvey();

  if (loading) {
    return (
      <SurveyContainer>
        <p>Die Umfragen werden geladen</p>
      </SurveyContainer>
    );
  }

  if (error) {
    return (
      <SurveyContainer>
        <p>Beim laden der Umfragen ist leider ein Fehler aufgetreten</p>
      </SurveyContainer>
    );
  }

  return (
    <SurveyContainer>
      <Questions survey={survey} user={user} />
    </SurveyContainer>
  );
};

export default Survey;
