import { NewsEntry } from "../../../types/news";
import getCustomDateFormat from "../getCustomDateFormat";
import NewsHeadline from "./NewsHeadline";
import { ReactComponent as CheckIcon } from "../icons/CheckIcon.svg";
import React from "react";

type HeadlineWithDateProps = {
  newsEntry: NewsEntry;
  isRead?: boolean;
};

const HeadlineWithDate = ({
  newsEntry,

  isRead,
}: HeadlineWithDateProps): JSX.Element => {
  const date =
    newsEntry && newsEntry.created_at
      ? getCustomDateFormat(newsEntry.created_at.toDate())
      : undefined;

  return (
    <div className="flex gap-5 flex-col sm:flex-row">
      <div className="flex gap-5 items-center">
        {isRead && <CheckIcon data-cy="CheckIcon" />}
        <NewsHeadline text={newsEntry.title} />
      </div>
      {!isRead && date && <span className="flex-grow sm:text-right text-sm">{date}</span>}
    </div>
  );
};

export default HeadlineWithDate;
