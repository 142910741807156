import React, { FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import { WrappedComponentProps as AuthComponentProps } from "react-with-firebase-auth";
import PrimaryButton from "../components/buttons/PrimaryButton";
import SubHeadline from "../components/headlines/SubHeadline";
import InputField from "../components/inputFields/InputField";
import UnauthenticatedView from "../components/layout/UnauthenticatedView";
import { createComponentWithAuth } from "../firebase/firebase";
import Feedback from "../components/Feedback";

const Login = ({ signInWithEmailAndPassword, error }: AuthComponentProps): JSX.Element => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLoginAttempt = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    signInWithEmailAndPassword(email, password);
  };

  return (
    <UnauthenticatedView>
      <form onSubmit={handleLoginAttempt}>
        <SubHeadline>Login</SubHeadline>
        <InputField
          name="email"
          type="email"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          placeholder="E-Mail"
          autocomplete="username"
        />
        <InputField
          name="password"
          type="password"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
          placeholder="Passwort"
          autocomplete="current-password"
        />

        <div className="text-center text-sm py-3">
          <Link className="underline" to="/passwordreset">
            Passwort vergessen?
          </Link>
        </div>
        <PrimaryButton name="login" label="Einloggen" isSubmitButton />
      </form>
      <Feedback
        isError
        text="Entweder ist das Passwort falsch oder der User existiert nicht."
        display={!!error}
      />
    </UnauthenticatedView>
  );
};

export default createComponentWithAuth(Login);
