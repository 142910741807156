type InputFieldProps = {
  name: string;
  placeholder: string;
  type: string;
  value: string;
  autocomplete: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputField = ({
  name,
  placeholder,
  type,
  value,
  autocomplete,
  onChange,
}: InputFieldProps): JSX.Element => (
  <div className="py-2">
    <input
      name={name}
      className="border leading-tight rounded text-black w-full px-5 py-3"
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      autoComplete={autocomplete}
    />
  </div>
);

export default InputField;
