import React from "react";
import Card from "../../components/cards/Card";

export type NewsItemProps = {
  linkTo?: string;
  isRead?: boolean;
  children: React.ReactNode;
};

const NewsItem = ({ linkTo, isRead = false, children }: NewsItemProps): JSX.Element => (
  <Card linkTo={linkTo} className={`${isRead ? "bg-gray-300 text-gray-500" : ""}`}>
    {children}
  </Card>
);

export default NewsItem;
