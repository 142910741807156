import { useDocument } from "react-firebase-hooks/firestore";
import { RouteComponentProps } from "react-router-dom";
import Loading from "../components/Loading";
import { STATIC_COLLECTION, USER_COLLECTION } from "../firebase/firestoreConstants";
import { useCurrentUser } from "../firebase/getUserContext";
import useCurrentCompanyDoc from "../firebase/useCurrentCompanyDoc";

type Params = {
  contentName: string;
};

const StaticContent = ({ match }: RouteComponentProps<Params>): JSX.Element => {
  const { contentName } = match.params;
  const currentUser = useCurrentUser();
  const companyDoc = useCurrentCompanyDoc();
  const [user] = useDocument(companyDoc.collection(USER_COLLECTION).doc(currentUser.uid));
  const currentRank: number = (user?.data()?.rank as number) ?? undefined;
  const nextRank = currentRank - 1;
  const diffToNextRank = (user?.data()?.diffToNextRank as number) ?? 0;
  const [value, loading, error] = useDocument(
    companyDoc.collection(STATIC_COLLECTION).doc(contentName),
  );

  if (loading) {
    return <Loading />;
  }

  const content = value?.data()?.content as string;

  return (
    <div className="w-11/12 lg:w-9/12 xl:w-1/2 p-5">
      {contentName === "score" && (
        <div className="text-center text-white text-xl font-medium bg-primary-dark-color p-5">
          {user?.exists && currentRank ? (
            <span>
              {nextRank === 0
                ? "Gratuliere! Du bist Rang 1."
                : `Du bist noch ${diffToNextRank} ${
                    diffToNextRank === 1 ? "Punkt" : "Punkte"
                  } von Rang ${nextRank} entfernt.`}
            </span>
          ) : (
            <span>
              Um einen Rang zu erreichen musst du zuerst Punkte machen, indem du News liest oder an
              Umfragen teilnimmst.
            </span>
          )}
        </div>
      )}
      <div className="flex flex-col p-5 bg-white">
        {error || !content ? (
          <p>Es gab einen Fehler beim Laden von {contentName}</p>
        ) : (
          <span dangerouslySetInnerHTML={{ __html: content }} />
        )}
      </div>
    </div>
  );
};

export default StaticContent;
