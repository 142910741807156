import React from "react";
import firebase from "firebase";
import { WrappedComponentProps as AuthComponentProps } from "react-with-firebase-auth";
import { Menu } from "@headlessui/react";
import { Link } from "react-router-dom";
import { auth, createComponentWithAuth } from "../../../firebase/firebase";
import StreakMenuItem from "./StreakMenuItem";
import RankMenuItem from "./RankMenuItem";

type MenuItemProps = {
  label: string;
  src: string;
  alt: string;
  linkTo: string;
};

const MenuItem = ({ label, src, alt, linkTo }: MenuItemProps) => (
  <li>
    <Link
      to={linkTo}
      className="flex-1 flex flex-col items-center justify-center text-white font-semibold text-xs text-center hover:bg-primary-dark-color rounded-lg p-1"
    >
      <img className="w-12" src={src} alt={alt} />
      <span className="pt-1">{label}</span>
    </Link>
  </li>
);

const UserMenuItem = ({ user }: TopMenuProps) => (
  <Menu as="div" className="relative inline-block text-left">
    <Menu.Button className="flex flex-col justify-center items-center rounded-lg font-medium text-xs text-white hover:bg-primary-dark-color w-full p-1">
      <img className="w-8" src="/user.svg" alt="user icon" />
      <span className="pt-2">
        Konto
        <img className="inline w-3 ml-2" src="/down-arrow.svg" alt="user icon" />
      </span>
    </Menu.Button>

    <Menu.Items className="absolute left-0 origin-top-right bg-white divide-y rounded-md shadow-lg ring-1 font-bold text-black text-md break-all w-56 sm:w-96 mt-2 ">
      <Menu.Item>
        <p className="flex flex-col rounded-md w-full px-2 py-3">
          <span>Angemeldet als:</span>
          <span className="font-normal">{user ? user.email : "anonymous"}</span>
        </p>
      </Menu.Item>
      <Menu.Item>
        <Link to="/passwordreset" className="flex w-full px-2 py-3 hover:bg-gray-200">
          Passwort ändern
        </Link>
      </Menu.Item>
      <Menu.Item>
        <a
          href="https://accilium.com/en/legal-notice/"
          rel="noreferrer"
          target="_blank"
          className="flex w-full px-2 py-3 hover:bg-gray-200"
        >
          Impressum
        </a>
      </Menu.Item>
      <Menu.Item>
        <button
          type="button"
          className="flex rounded-md rounded-t-none w-full px-2 py-3 hover:bg-gray-200"
          onClick={() => {
            auth.signOut().catch((e) => console.error(e));
          }}
        >
          <div>
            <img className="inline w-6 ml-1" src="/exit.svg" alt="user icon" />{" "}
            <span className="pl-1">Ausloggen</span>
          </div>
        </button>
      </Menu.Item>
    </Menu.Items>
  </Menu>
);

type TopMenuProps = {
  user: firebase.User | undefined;
};

const TopMenu = ({ user }: TopMenuProps) => (
  <nav className="fixed top-0 bg-primary-main-color text-white shadow-lg w-full">
    <ol className="flex flex-row justify-between list-none max-w-6xl mx-auto px-4 py-2">
      <UserMenuItem user={user} />
      <RankMenuItem user={user} src="/rank.svg" alt="A flame" linkTo="/score" />
      <StreakMenuItem user={user} src="/streak.svg" alt="A flame" linkTo="/score" />
    </ol>
  </nav>
);

const BottomMenu = () => (
  <nav className="flex flex-col items-center w-full">
    <ol className="flex flex-row items-center justify-center fixed bottom-0 bg-primary-main-color list-none space-x-0 sm:space-x-5 w-full md:max-w-md max-w-6xl px-4 py-2">
      <MenuItem
        linkTo="/news"
        label="Neuigkeiten"
        src="/news.svg"
        alt="A globe with News written in front of it"
      />
      <MenuItem
        linkTo="/buddy"
        label="Change-Partner"
        src="/changebuddy.svg"
        alt="Two user icons"
      />
      <MenuItem
        linkTo="/story"
        label="Hintergründe"
        src="/changestory.svg"
        alt="An open story book"
      />
      <MenuItem
        linkTo="/survey"
        label="Umfragen"
        src="/survey.svg"
        alt="A writing board with a pen and an emoticon next to it"
      />
    </ol>
  </nav>
);

type AuthenticatedViewProps = {
  children: React.ReactNode;
} & AuthComponentProps;

const AuthenticatedView = ({ children, user }: AuthenticatedViewProps): JSX.Element => (
  <div>
    <TopMenu user={user || undefined} />
    <div className="flex flex-col items-center min-h-screen bg-info-color break-words pb-24 pt-20">
      {children}
    </div>
    <BottomMenu />
  </div>
);

export default createComponentWithAuth(AuthenticatedView);
