import { Link } from "react-router-dom";

type CardWithLinkProps = {
  linkTo: string;
  className?: string;
  children: React.ReactNode;
};

const CardWithLink = ({ linkTo, className = "", children }: CardWithLinkProps): JSX.Element => (
  <div className={`flex w-full bg-white hover:bg-gray-200 ${className}`}>
    <Link to={linkTo} className={`w-full py-3 px-5`}>
      {children}
    </Link>
  </div>
);

type CardProps = {
  linkTo?: string;
  className?: string;
  children: React.ReactNode;
};

const Card = ({ linkTo, className = "", children }: CardProps): JSX.Element => {
  if (linkTo) {
    return (
      <CardWithLink linkTo={linkTo} className={className}>
        {children}
      </CardWithLink>
    );
  }

  return <div className={`flex px-5 py-3 w-full bg-white ${className}`}>{children}</div>;
};

export default Card;
