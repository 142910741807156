import { Link } from "react-router-dom";

type SecondaryAnchorProps = {
  label: string;
  to: string;
};

const SecondaryAnchor = ({ label, to }: SecondaryAnchorProps): JSX.Element => (
  <Link
    className="block rounded-lg border-2 bg-gray-300 hover:bg-gray-400 border-gray-100 text-black font-bold text-center py-2 w-full"
    to={to}
  >
    {label}
  </Link>
);

export default SecondaryAnchor;
