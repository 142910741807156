import firebase from "firebase";
import { useEffect, useMemo, useRef, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Question } from "./types/Question";
import { Survey } from "./types/Survey";
import { SURVEY_COLLECTION, QUESTION_COLLECTION } from "../firebase/firestoreConstants";
import useCurrentCompanyDoc from "../firebase/useCurrentCompanyDoc";

export default function useSurvey(): [Survey, boolean, firebase.FirebaseError | undefined] {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<firebase.FirebaseError | undefined>(undefined);

  const now = useMemo(() => {
    const thisMinute = new Date();
    thisMinute.setSeconds(0);
    thisMinute.setMilliseconds(0);
    return firebase.firestore.Timestamp.fromDate(thisMinute);
  }, []);
  const companyDoc = useCurrentCompanyDoc();
  const companyDocRef = useRef(companyDoc);
  companyDocRef.current = companyDoc;

  const [surveys, surveysLoading, surveysError] = useCollectionData<Survey>(
    companyDoc
      .collection(SURVEY_COLLECTION)
      .where("start_at", "<=", now)
      .orderBy("start_at", "desc"),
    { idField: "id" },
  );

  const latestSurvey = getLatestUnexpiredSurvey(now, surveys);

  const questionsQuery = useMemo(
    () =>
      companyDocRef.current
        .collection(SURVEY_COLLECTION)
        .doc(latestSurvey?.id)
        .collection(QUESTION_COLLECTION)
        .orderBy("order", "asc"),
    [latestSurvey],
  );
  const [questions, questionsLoading, questionsError] = useCollectionData<Question>(
    questionsQuery,
    { idField: "id" },
  );

  useEffect(() => {
    setLoading(surveysLoading || questionsLoading);
  }, [surveysLoading, questionsLoading]);

  useEffect(() => {
    setError(questionsError || surveysError);
  }, [questionsError, surveysError]);

  return [
    { ...latestSurvey, questions: latestSurvey && questions } as unknown as Survey,
    loading,
    error,
  ];
}

function getLatestUnexpiredSurvey(
  now: firebase.firestore.Timestamp,
  surveys?: Survey[],
): Survey | undefined {
  return surveys?.find((survey) => survey.expire_at.toDate() > now.toDate());
}
