import firebase from "firebase/app";
import moment from "moment";
import useSurvey from "../../survey/useSurvey";
import useOpenQuestions from "../../survey/useOpenQuestions";
import { ReactComponent as PencilIcon } from "./PencilIcon.svg";
import { ReactComponent as CheckIcon } from "../../components/icons/CheckIcon.svg";
import Card from "../../components/cards/Card";
import Loading from "../../components/Loading";

require("moment/locale/de");

type SurveyNotificationProps = {
  user: firebase.User;
};

type NotificationCardProps = {
  message: string;
  Icon: React.ComponentType;
};

const NotificationCard = ({ message, Icon }: NotificationCardProps): JSX.Element => (
  <Card linkTo="/survey" className="bg-primary-main-color hover:bg-primary-main-color">
    <div className="flex justify-between w-full">
      <div className="text-xl font-bold">{message}</div>
      <Icon />
    </div>
  </Card>
);

const SurveyNotification = ({ user }: SurveyNotificationProps): JSX.Element | null => {
  const [survey, loadingQuestions, errorQuestions] = useSurvey();
  const [openQuestions, loadingOpenQuestions, errorOpenQuestions] = useOpenQuestions(survey, user);

  if (loadingQuestions || loadingOpenQuestions) {
    return <Loading />;
  }

  if (errorQuestions || errorOpenQuestions || !survey.questions || !openQuestions) {
    return null;
  }

  if (openQuestions.length <= 0) {
    return (
      <Card className="bg-gray-300 text-gray-500 space-x-5">
        <CheckIcon />
        <div> Aktuelle Umfrage erledigt</div>
      </Card>
    );
  }

  if (openQuestions.length < survey.questions.length) {
    return (
      <NotificationCard
        message={`Umfrage fortsetzen (endet am ${getSurveyEndDateDescription(survey.expire_at)})`}
        Icon={PencilIcon}
      />
    );
  }

  return (
    <NotificationCard
      message={`Aktuelle Umfrage starten (endet am ${getSurveyEndDateDescription(
        survey.expire_at,
      )})`}
      Icon={PencilIcon}
    />
  );
};

function getSurveyEndDateDescription(date?: firebase.firestore.Timestamp) {
  if (!date) {
    return "";
  }

  const parsedDate = moment(date.toDate());
  return `${parsedDate.format("DoMM.YYYY")} um ${parsedDate.format("h:mm")}`;
}

export default SurveyNotification;
