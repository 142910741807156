import { useState, useEffect } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { NewsEntry } from "../../types/news";
import firebase from "../firebase/firebase";
import { NEWS_COLLECTION, FETCH_LIMIT } from "../firebase/firestoreConstants";
import useCurrentCompanyDoc from "../firebase/useCurrentCompanyDoc";

function getPaginationStartAfter(newsEntries: NewsEntry[] | undefined) {
  if (newsEntries && newsEntries.length !== 0) {
    const lastEntry = newsEntries[newsEntries.length - 1];
    return lastEntry.created_at;
  }

  return getZeroTimestamp();
}

function getZeroTimestamp() {
  // eslint-disable-next-line import/no-named-as-default-member
  return new firebase.firestore.Timestamp(0, 0);
}

function usePaginatedNewsData(): [
  NewsEntry[] | undefined,
  boolean,
  firebase.FirebaseError | undefined,
  boolean,
  () => void,
] {
  const companyDoc = useCurrentCompanyDoc();

  const [newsEntries, setNewsEntries] = useState<NewsEntry[] | undefined>(undefined);
  const [allNewsEntriesLoaded, setAllNewsEntriesLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<firebase.FirebaseError | undefined>(undefined);

  const [initialNewsEntries, initialLoading, initialError] = useCollectionData<NewsEntry>(
    companyDoc.collection(NEWS_COLLECTION).orderBy("created_at", "desc").limit(FETCH_LIMIT),

    {
      idField: "id",
    },
  );

  useEffect(() => {
    setNewsEntries(initialNewsEntries);
  }, [initialNewsEntries]);

  useEffect(() => {
    setLoading(initialLoading);
  }, [initialLoading]);

  useEffect(() => {
    setError(initialError);
  }, [initialError]);

  const loadNext = async () => {
    setLoading(true);
    setError(undefined);

    const collectionRef = companyDoc
      .collection(NEWS_COLLECTION)
      .orderBy("created_at", "desc")
      .startAfter(getPaginationStartAfter(newsEntries))
      .limit(FETCH_LIMIT);

    try {
      const nextNewsEntriesSnapshot = await collectionRef.get();
      if (nextNewsEntriesSnapshot.size < FETCH_LIMIT) {
        setAllNewsEntriesLoaded(true);
      }

      const nextNewsEntries: NewsEntry[] = nextNewsEntriesSnapshot.docs.map((newsDoc) => {
        const news = newsDoc.data() as NewsEntry;
        news.id = newsDoc.id;
        return news;
      });

      setNewsEntries((prevNewsEntries) => {
        if (prevNewsEntries) {
          return [...prevNewsEntries, ...nextNewsEntries];
        }

        return [...nextNewsEntries];
      });
    } catch (firebaseError) {
      setError(firebaseError as firebase.FirebaseError);
    } finally {
      setLoading(false);
    }
  };

  return [newsEntries, loading, error, allNewsEntriesLoaded, loadNext];
}

export default usePaginatedNewsData;
