import firebase from "firebase/app";
import Question from "./Question";
import AllQuestionsAnswered from "./AllQuestionsAnswered";
import ProgressBar from "../components/ProgressBar";
import NoSurvey from "./NoSurvey";
import useOpenQuestions from "./useOpenQuestions";
import { Survey } from "./types/Survey";

type QuestionsProps = {
  survey: Survey;
  user: firebase.User;
};

const calculateProgressPercentage = (answers: number, questions: number) =>
  answers > 0 && questions > 0 ? (answers / questions) * 100 : 0;

const Questions = ({ survey, user }: QuestionsProps): JSX.Element | null => {
  const [openQuestions, loading, error] = useOpenQuestions(survey, user);

  if (!survey.questions) {
    return <NoSurvey />;
  }

  if (loading) {
    // TODO show loading message
    return <div>loading...</div>;
  }

  if (error || !openQuestions) {
    // TODO show error message
    return null;
  }

  const questionsAmount = survey.questions.length;
  const answered = questionsAmount - openQuestions.length;
  const answeredPercentage = calculateProgressPercentage(answered, questionsAmount);

  if (openQuestions.length <= 0) {
    return <AllQuestionsAnswered />;
  }

  const nextOpenQuestion = openQuestions[0];
  return (
    <>
      <div className="w-full text-center py-5">
        <span className="text-lg font-bold">
          {answered} von {questionsAmount} beantwortet
        </span>
        <ProgressBar progressPercentage={answeredPercentage} />
      </div>

      <Question
        key={nextOpenQuestion.id}
        surveyId={survey.id}
        question={nextOpenQuestion}
        currentQuestion={answered + 1}
      />
    </>
  );
};

export default Questions;
