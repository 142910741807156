type ProgressBarProps = {
  progressPercentage: number;
};
const ProgressBar = ({ progressPercentage }: ProgressBarProps): JSX.Element => (
  <div className="shadow-md rounded-md bg-white h-7 w-full">
    <div style={{ width: `${progressPercentage}%` }} className="bg-primary-dark-color h-full" />
  </div>
);

export default ProgressBar;
