import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./ErrorFallback";

type Props = {
  children: React.ReactNode;
};

const AppErrorBoundary = ({ children }: Props): JSX.Element => (
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onError={(error: Error, info: { componentStack: string }) => {
      console.error(error, info);
    }}
  >
    {children}
  </ErrorBoundary>
);

export default AppErrorBoundary;
