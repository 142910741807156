import firebase from "firebase";
import { useDocument } from "react-firebase-hooks/firestore";
import striptags from "striptags";
import { NewsEntry } from "../../types/news";
import NewsItem from "./layout/NewsItem";
import Loading from "../components/Loading";
import usePaginatedNewsData from "./usePaginatedNewsData";
import ArticleContainer from "../components/layout/ArticleContainer";
import SurveyNotification from "./surveyNotification/SurveyNotification";
import HeadlineWithDate from "../components/headlines/HeadlineWithDate";
import WelcomeMessage from "../components/WelcomeMessage";
import { USER_COLLECTION } from "../firebase/firestoreConstants";
import useCurrentCompanyDoc from "../firebase/useCurrentCompanyDoc";
import { Link } from "react-router-dom";

type NewsItemPreviewProps = {
  newsEntry: NewsEntry;
  isRead: boolean;
};

type NewsProps = {
  user: firebase.User;
};

type ReadNews = {
  readNewsArray: string[];
};

type ReadMoreProps = { allNewsEntriesLoaded: boolean; loadNext: () => void };

const NewsItemPreview = ({ newsEntry, isRead }: NewsItemPreviewProps) => {
  const likes = newsEntry.likedByUsers ? newsEntry.likedByUsers.length : 0;
  return (
    <NewsItem isRead={isRead} linkTo={`/news/${newsEntry.id}`}>
      <HeadlineWithDate isRead={isRead} newsEntry={newsEntry} />
      {!isRead && (
        <div
          className="text-left whitespace-nowrap truncate sm:w-3/4 w-full mt-3"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: newsEntry.content ? striptags(newsEntry.content) : "No content",
          }}
        />
      )}
      <div
        className={`row-span-full mt-3 pt-3 border-t border-info-color flex items-center justify-between`}
      >
        <span className="font-bold">
          {likes} {`${likes === 1 ? "like" : "likes"}`}
        </span>

        <Link to={`/news/${newsEntry.id}`} className="underline hover:text-gray-400">
          Weiterlesen
        </Link>
      </div>
    </NewsItem>
  );
};

const ReadMore = ({ allNewsEntriesLoaded, loadNext }: ReadMoreProps) =>
  allNewsEntriesLoaded ? (
    <span>Keine weiteren Nachrichten.</span>
  ) : (
    <button type="button" className="flex mx-auto underline text-lg" onClick={() => loadNext()}>
      Mehr Laden
    </button>
  );

const News = ({ user }: NewsProps): JSX.Element => {
  const companyDoc = useCurrentCompanyDoc();
  const [newsEntries, loading, error, allNewsEntriesLoaded, loadNext] = usePaginatedNewsData();
  const [readNewsDocument] = useDocument(companyDoc.collection(USER_COLLECTION).doc(user.uid));

  const readNewsData = readNewsDocument?.data() as unknown as ReadNews;
  const readNews = readNewsData ? readNewsData.readNewsArray : [];

  if (loading) {
    return <Loading />;
  }

  if (error) {
    console.error("An error has occurred while fetching data from the firestore: ", error);
    return (
      <p>
        Beim laden der Neuigkeiten ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.
      </p>
    );
  }

  return (
    <ArticleContainer>
      <WelcomeMessage user={user} />
      <SurveyNotification user={user} />
      {newsEntries && newsEntries.length > 0 ? (
        <>
          {newsEntries.map((news: NewsEntry) => (
            <NewsItemPreview
              isRead={readNews && readNews.includes(news.id)}
              key={news.id}
              newsEntry={news}
            />
          ))}
          <ReadMore allNewsEntriesLoaded={allNewsEntriesLoaded} loadNext={loadNext} />
        </>
      ) : (
        <p>Es gibt noch keine Nachrichten</p>
      )}
    </ArticleContainer>
  );
};

export default News;
