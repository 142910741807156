import { Link } from "react-router-dom";
import React from "react";
import firebase from "firebase/app";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { USER_COLLECTION } from "../../../firebase/firestoreConstants";
import useCurrentCompanyDoc from "../../../firebase/useCurrentCompanyDoc";

type StreakItemProps = {
  src: string;
  alt: string;
  linkTo: string;
  user: firebase.User | undefined;
};

type UserData = {
  streak: number;
};

const StreakMenuItem = ({ src, alt, linkTo, user }: StreakItemProps): JSX.Element => {
  const companyDoc = useCurrentCompanyDoc();
  const [userData] = useDocumentData<UserData>(
    companyDoc.collection(USER_COLLECTION).doc(user?.uid),
    {},
  );

  return (
    <li>
      <Link
        to={linkTo}
        className="flex-1 flex flex-col items-center justify-center text-white text-xs text-center hover:bg-primary-dark-color rounded-lg p-1"
      >
        <div className="flex flex-row">
          <img className="w-7" src={src} alt={alt} />
          <span className="text-4xl pl-1" data-cy="streak">
            {userData?.streak || 0}
          </span>
        </div>
        <span className="pt-1 font-semibold">Score</span>
      </Link>
    </li>
  );
};

export default StreakMenuItem;
