import firebase from "firebase";
import { useState } from "react";
import PrimaryButton from "../components/buttons/PrimaryButton";
import SubHeadline from "../components/headlines/SubHeadline";
import InputField from "../components/inputFields/InputField";
import UnauthenticatedView from "../components/layout/UnauthenticatedView";
import Feedback from "../components/Feedback";
import SecondaryAnchor from "../components/anchors/SecondaryAnchor";

const PasswordReset = (): JSX.Element => {
  const [email, setEmail] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setShowSuccess(true);
        setShowError(false);
      })
      .catch((e) => {
        console.error("An error has occurred while attempting to reset password", e);
        setShowSuccess(false);
        setShowError(true);
      });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <UnauthenticatedView>
      <form onSubmit={handleSubmit}>
        <SubHeadline>Passwort ändern</SubHeadline>
        <p className="py-5 max-w-md">
          Bitte gib deine Email Adresse ein. Wir schicken dir eine E-Mail zum zurücksetzen deines
          Passworts.
        </p>
        <Feedback
          text="Email zum Zurücksetzen wurde versendet. Bitte überprüfe deinen Spam Ordner, solltest du die E-mail zur Bestätigung nicht finden."
          display={showSuccess}
          isError={false}
        />
        <Feedback
          text="Es ist ein Fehler aufgetreten. Bitte versuche es später nochmal."
          display={showError}
          isError
        />
        <InputField
          name="passwordreset_email"
          type="email"
          placeholder="example@mail.com"
          value={email}
          onChange={handleEmailChange}
          autocomplete="username"
        />
        <div className="pt-8" />
        <PrimaryButton name="passwordreset_submit" label="Zurücksetzen" isSubmitButton />
        <div className="pt-3" />
        <SecondaryAnchor label="Abbrechen" to="/" />
      </form>
    </UnauthenticatedView>
  );
};

export default PasswordReset;
