import firebase from "firebase/app";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Answer } from "./types/Answer";
import { Question } from "./types/Question";
import { USER_COLLECTION, ANSWER_COLLECTION } from "../firebase/firestoreConstants";
import useCurrentCompanyDoc from "../firebase/useCurrentCompanyDoc";
import { toSurveyQuestionId } from "./answerUtils";
import { Survey } from "./types/Survey";

export default function useOpenQuestions(
  survey: Survey | undefined,
  user: firebase.User,
): [Question[] | undefined, boolean, firebase.FirebaseError | undefined] {
  const companyDoc = useCurrentCompanyDoc();
  const [answers, loading, error] = useCollectionData<Answer>(
    companyDoc.collection(USER_COLLECTION).doc(user.uid).collection(ANSWER_COLLECTION),
    { idField: "id" },
  );

  const idTuples = answers?.map((answer) => toSurveyQuestionId(answer.id));
  const idTuplesOfCurrentSurvey = idTuples?.filter((tuple) => tuple.surveyId === survey?.id);

  const openQuestions = survey?.questions?.filter(
    (question: Question) =>
      !idTuplesOfCurrentSurvey?.some((tuple) => tuple.questionId === question.id),
  );

  return [openQuestions, loading, error];
}
